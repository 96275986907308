<template>
  <el-dialog title="遥感情况" :visible="showRsRecordsFlag" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
    <div class="content-wrap">
      <div class="footer-wrap" v-if="!recordsHistory">
        <div class="no-rs-data">
          <img src="@/images/remoteSensing/rs-refresh.png" alt="" />
        </div>
      </div>
      <div class="zhangshi-tips">
        <img src="@/images/remoteSensing/zuowu_tip.png" alt="" />
      </div>
      <div class="zoom-tool-wrap">
        <div class="tool-btn" @click="zoomAdd">
          <span class="zn-zoom-icon icon iconfont icon-jiahao"></span>
        </div>
        <div class="tool-btn" @click="zoomCut">
          <span class="zn-zoom-icon icon iconfont icon-jianhao"></span>
        </div>
      </div>
      <div id="map"></div>
    </div>
  </el-dialog>
</template>
<script>
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import { googleMap } from '@/config/map.js';
import * as utils from '@/utils/utils.js';
import { remoteSenseDetail } from '@/api/iotPlant/api.js';
export default {
  name: 'rsRecords',
  props: {
    landInfo: null,
    showRsRecordsFlag: false
  },
  data() {
    return {
      records: [],
      recordsHistory: null,
      recordsPageInfo: {
        landId: ''
      },
      locLat: '33.446385', // 当前的维度,
      locLng: '114.071285' // 当前的经度
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  methods: {
    closeDg() {
      this.$emit('update:showRsRecordsFlag', false);
    },
    initMap() {
      // 地图初始化参数
      let initParam = {
        L,
        minZoom: 3,
        maxZoom: 20,
        center: [this.locLat, this.locLng],
        mapType: googleMap,
        zoom: 12,
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.5,
        preferCanvas: true,
        crs: L.CRS.EPSG3857
      };
      // 初始化地图
      this.map = utils.initMap(initParam);
      this.getRsRecords();
    },
    zoomAdd() {
      this.map && this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomCut() {
      this.map && this.map.setZoom(this.map.getZoom() - 1);
    },
    closeDg1() {
      this.$emit('update:showRsRecordsFlag', false);
    },
    getRsRecords() {
      let params = { landId: this.landInfo.id };
      remoteSenseDetail(params)
        .then((res) => {
          this.recordsHistory = res;
          this.renderImg();
        })
        .catch((error) => {});
    },

    renderImg() {
      let rsObj = this.recordsHistory || {};
      if (rsObj.images && rsObj.images.length > 0) {
        rsObj.images.forEach((item) => {
          let imageBounds = item.coordinateBiased;
          let imageUrl = item.remoteSenseImgUrl;
          let rsLayer = L.imageOverlay(imageUrl, imageBounds, { opacity: 1, className: 'rs-img' }); //opacity是透明度
          this.map.addLayer(rsLayer);
          this.map.fitBounds(item.coordinateBiased);
        });
      }
    }
  }
};
</script>
<style scoped>
#map >>> .rs-img {
  /* filter: invert(15%) saturate(90%) hue-rotate(20deg) contrast(150%); */
  filter: invert(15%) saturate(90%) hue-rotate(20deg);
}
</style>
<style scoped lang="scss">
#map {
  width: 100%;
  height: 600px;
  z-index: 1;
}
.content-wrap {
  position: relative;
}
.left {
  position: absolute;
  left: 10px;
  top: 100px;
  z-index: 999;
  .left-box {
    margin-bottom: 20px;
    padding-bottom: 10px;
    .box-view {
      margin: 10px 21px 0 21px;
      color: white;
      height: 265px;
      overflow: hidden;
      .item-info {
        margin-bottom: 10px;
        display: flex;
        cursor: pointer;
      }
      .item-detail {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 7px;
        .item-title {
          font-size: 14px;
          font-family: Source Han Sans CN;
          color: #ffffff;
          line-height: 15px;
          .icon {
            color: #fa5555;
            font-size: 16px;
            margin-left: 5px;
          }
        }
        .item-content {
          color: #2cafff;
          line-height: 18px;
        }
      }
    }
  }
}
.right {
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 999;
  .right-view {
    width: 150px;
    min-height: 92px;
    box-sizing: border-box;
    background: rgba(19, 38, 73, 0.6);
    margin-bottom: 10px;
  }
  .patrol-category {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #bddbff;
    padding: 29px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 29px;
      height: 26px;
      font-size: 26px;
    }
    &.active {
      border: 1px solid #12e7e8;
      color: #00feff;
    }
  }
  .guihua {
    padding: 15px 18px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #bddbff;
    line-height: 36px;
    .el-checkbox__label {
      color: #bddbff;
    }
  }
  .skip-szzt-btn {
    width: 180px;
    height: 40px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.footer-wrap {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  height: 100px;
  .footer-content-wrap {
    max-width: 600px;
    overflow-x: hidden;
    white-space: nowrap;
    .rsSwiper {
      position: initial;
      width: 100%;
      height: 100%;
    }
  }
  .common {
    width: 43px;
    height: 100px;
    background: rgba(27, 34, 48, 0.8);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    .arrow {
      width: 17px;
      height: 34px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  .prev {
    margin-right: 10px;
  }
  .next {
    margin-left: 10px;
  }
  .content {
    margin-left: 5px;
    width: 114px !important;
    color: #bddbff;
    padding: 10px 0;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #00feff;
    font-size: 14px;
    cursor: pointer;
    &.active {
      color: #00feff;
      border: 2px solid #00feff;
    }
    .swiper-item {
      text-align: center;
      .cloud-img {
        width: 34px;
        height: 34px;
      }
    }
  }
  .danger-bg {
    background: rgba(42, 20, 20, 0.8);
    border-top: 2px solid #8c3c3c;
  }
}
.tips {
  display: flex;
  position: absolute;
  bottom: 180px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  height: 40px;
  background: rgba(27, 34, 48, 0.8);
  .tips-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #bddbff;
    line-height: 36px;
    padding: 0 20px;
    border-radius: 4px;
    .color-block {
      width: 20px;
      height: 20px;
      background: #f49300;
      margin-right: 4px;
    }
    .block2 {
      background: #9f6df4;
    }
    .block3 {
      background: #f4e400;
    }
    .block4 {
      background: #00f4c9;
    }
  }
}

.zhangshi-tips {
  width: 36px;
  height: 300px;
  position: absolute;
  left: 10px;
  top: 40px;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
  }
}
.zoom-tool-wrap {
  position: absolute;
  left: 10px;
  bottom: 140px;
  z-index: 2;
  .tool-btn {
    display: block;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 5px;
    text-decoration: none;
    cursor: pointer;
    a {
    }
  }
}
.one-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.no-rs-data {
  background: url('~@/images/remoteSensing/rs-no-data-bg.png') center center no-repeat;
  background-size: 100%;
  height: 83px;
  width: 568px;
  margin: 10px 17px;
  img {
    animation: rsRefresh 3s ease-in-out infinite;
    height: 83px;
    width: 568px;
  }
}
@keyframes rsRefresh {
  0% {
    width: 0;
  }
  100% {
    width: 568px;
  }
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
</style>
