<template>
  <div>
    <el-row style="margin-bottom: 12px">
      <el-col :span="12" class="flex-row">
        <span class="span1">消息预警时间</span>
        <el-date-picker
          style="width: 60%"
          v-model="applytDate"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>

    <el-table :data="tableData" stripe header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="createTime" label="消息预警时间"> </el-table-column>

      <el-table-column prop="msgTitle" label="标题"> </el-table-column>
      <el-table-column prop="description" show-overflow-tooltip label="防治方案"> </el-table-column>
      <el-table-column prop="content" show-overflow-tooltip label="专家诊断"> </el-table-column>
      <el-table-column prop="userName" label="接收账号"> </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { diagnoseList } from '@/api/insectAttack/api.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data() {
    return {
      applytDate: [],

      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },

      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      this.searchParams = {
        startTime: this.applytDate && this.applytDate[0] ? dayjs(this.applytDate[0]).format('YYYY-MM-DD HH:mm:ss').toString() : '',
        endTime: this.applytDate && this.applytDate[1] ? dayjs(this.applytDate[1]).format('YYYY-MM-DD HH:mm:ss').toString() : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      diagnoseList({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
