<template>
  <div>
    <el-row>
      <el-col class="search-item" :span="6">
        <div class="titile">地块名称:</div>
        <el-input size="mini" v-model="searchParams.name" clearable></el-input>
      </el-col>
      <el-col class="search-item" :span="6">
        <div class="titile">种植作物:</div>
        <el-select size="mini" v-model="searchParams.cropsCategoryId" placeholder="请选择" @change="onOptionChange" clearable>
          <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <el-select size="mini" v-model="searchParams.cropsId" placeholder="请选择" clearable>
          <el-option v-for="item in categorySubList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col class="search-item" :span="6">
        <el-button size="mini" type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
        <el-button style="margin-left: 12px; background-color: #f2f3f5" size="mini" icon="el-icon-refresh-left" @click="searchParams = {}"
          >重置</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <!-- <el-table-column label="种植历史">
        <template slot-scope="scope">
          {{ getPalantingData(scope.row) }}
        </template>
      </el-table-column> -->
      <el-table-column prop="name" label="地块名称"></el-table-column>
      <el-table-column label="地块面积(亩)">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.area) }}</template>
      </el-table-column>
      <el-table-column prop="cropsACategoryName" label="种植作物"> </el-table-column>
      <el-table-column prop="cropsAName" label="作物品种"> </el-table-column>
      <el-table-column label="种植面积(亩)">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.plantArea) }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toDetail(scope.row, 1)">查看遥感</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <rsRecords :landInfo="landInfo" :showRsRecordsFlag.sync="showRsRecordsFlag" v-if="showRsRecordsFlag"></rsRecords>
  </div>
</template>

<script>
import { mqList } from '@/api/iotPlant/api.js';
import { postRequest, getRequest } from '@/api/api.js';
import rsRecords from './components/rsRecords.vue';
import * as utils from '@/utils/utils.js';

import dayjs from 'dayjs';
export default {
  components: {
    rsRecords
  },
  data() {
    return {
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: [],
      showRsRecordsFlag: false,
      categoryList: [],
      categorySubList: []
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getCategoryList();
  },
  methods: {
    getPalantingData(item) {
      let start = item.startDate ? dayjs(item.startDate).format('MM-DD') : '';
      let end = item.endDate ? dayjs(item.endDate).format('MM-DD') : '';
      return start + '-' + end;
    },
    onSearch() {
      this.pageInfo.currentPage = 1;

      this.getList();
    },
    onOptionChange(id) {
      if (this.searchParams.cropsId) {
        this.searchParams.cropsId = '';
      }
      this.categoryList.map((e) => {
        if (id == e.id) {
          this.categorySubList = e.species || [];
          // if (e.species && e.species.length) {
          //   this.searchParams.cropsId = e.species[0].id;
          // }
        }
      });
    },
    getCategoryList() {
      this.categoryList = [];
      getRequest('/api/operate/planting/scheme/category/list', {})
        .then((res) => {
          this.categoryList = res;
        })
        .catch((error) => {});
    },
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    getList() {
      mqList({
        ...this.searchParams,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    toDetail(item) {
      this.landInfo = item;
      this.showRsRecordsFlag = true;
    }
  }
};
</script>
<style scoped lang="scss">
@import '@/assets/common.scss';
</style>
