<template>
  <el-dialog :title="dialogTitle" :visible="dialogVisible" width="40%" @close="onClose" destroy-on-close :close-on-click-modal="false">
    <el-form ref="form" label-width="120px">
      <el-form-item label="防治方案:">
        <el-input type="textarea" autosize v-model="scheme"></el-input>
      </el-form-item>
      <el-form-item label="诊断结果:">
        <el-input type="textarea" autosize v-model="content"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '确定' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { answer } from '@/api/insectAttack/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    isDisable: false,
    currentId: ''
  },
  components: {},
  computed: {
    dialogTitle() {
      return '诊断方案';
    }
  },
  data() {
    return {
      content: '',
      scheme: '',
      id: ''
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.content = '';
        this.scheme = '';
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      if (!this.content) {
        this.$message('请输入答案');
        return;
      }

      answer({ landName: this.currentId.landName, id: this.currentId.id, expertDiagnosis: this.content, description: this.scheme }).then(e => {
        this.$message('回答成功');
        this.$emit('update:dialogVisible', false);
        this.$emit('updateList');
      }).catch(error => {

      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
