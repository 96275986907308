<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="deviceCode" label="设备序列号"> </el-table-column>
      <el-table-column prop="date" label="日期"> </el-table-column>
      <el-table-column prop="airhumidity" label="空气湿度(RH%)"> </el-table-column>
      <el-table-column prop="airtemperature" label="空气温度(℃）"> </el-table-column>
      <el-table-column prop="lightintensity" label="光照强度(Klux)"> </el-table-column>
      <el-table-column prop="soiltemperature" label="土壤温度(℃)"> </el-table-column>
      <el-table-column prop="soilhumidity" label="土壤湿度(VOL%)"> </el-table-column>
      <el-table-column prop="rainfall" label="降雨量(mm)"> </el-table-column>
      <el-table-column prop="winddirection" label="风向"> </el-table-column>
      <el-table-column prop="windpower" label="风速(m/s)"> </el-table-column>
    </el-table>
    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { qxList } from '@/api/iotPlant/api.js';
export default {
  components: {},
  data() {
    return {
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize
      };
      qxList(params)
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss"></style>
