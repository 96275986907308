<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>

      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="deviceCode" label="设备序列号"> </el-table-column>
      <el-table-column prop="date" label="日期"> </el-table-column>

      <el-table-column prop="soiltemperature" label="土壤温度(℃)"> </el-table-column>
      <el-table-column prop="soilhumidity" label="土壤湿度(VOL%)"> </el-table-column>
      <el-table-column prop="soiltemperature2" label="土壤温度2(℃)"> </el-table-column>
      <el-table-column prop="soilhumidity2" label="土壤湿度2(VOL%)"> </el-table-column>
      <el-table-column prop="soiltemperature3" label="土壤温度3(℃)"> </el-table-column>
      <el-table-column prop="soilhumidity3" label="土壤湿度3(VOL%)"> </el-table-column>
      <el-table-column prop="soiltemperature4" label="土壤温度4(℃)"> </el-table-column>
      <el-table-column prop="soilhumidity4" label="土壤湿度4(VOL%)"> </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { trList } from '@/api/iotPlant/api.js';
export default {
  components: {},
  data() {
    return {
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      trList({ pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss"></style>
